var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"batchCancelBtn"},[_c('el-button',{attrs:{"type":"info","size":"small","loading":_vm.btnLonding},on:{"click":_vm.showDialog}},[_vm._v("取消分配")]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" 取消分配 ")]),_c('CommonTable',{attrs:{"height":"auto","max-height":"400px","selection":false,"cols":_vm.tableCol1,"infoData":_vm.tableData},scopedSlots:_vm._u([{key:"mergeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',{staticClass:"merge-slot-class"},[_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(scoped),"fit":"contain"}}),_c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(scoped.name || '暂无名字')+" ")])])],1)]}},{key:"CategorySlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s((scoped.productCategory && scoped.productCategory.name) || '暂无')+" ")]}},{key:"structSlot",fn:function(ref){
var styleList = ref.scoped.styleList;
return [(!styleList.length)?_c('div',[_vm._v(" "+_vm._s('暂无款式')+" ")]):(styleList.length === 1)?_c('div',[_c('el-tag',[_vm._v(_vm._s(styleList[0].name || '暂无款式名'))])],1):_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"200","trigger":"hover","visible-arrow":false,"popper-class":"assignData-style"}},[_c('div',[_c('p',{staticClass:"assignData-style-header"},[_c('span',{staticClass:"assignData-style-header-font"},[_vm._v(" 全部款式")])]),_c('div',{staticClass:"assignData-style-tag-concent"},_vm._l((styleList),function(item){return _c('el-tag',{key:item.id,staticStyle:{"margin-right":"5px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(item.name || '暂无款式名')+" ")])}),1)]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-tag',[_vm._v(_vm._s(styleList[0].name || '暂无款式名'))]),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"margin-left":"5px","display":"inline-block"}})],1)])],1)]}},{key:"sizesSlot",fn:function(ref){
var sizeList = ref.scoped.sizeList;
return [(!sizeList.length)?_c('div',[_vm._v(" "+_vm._s('暂无款式')+" ")]):(sizeList.length === 1)?_c('div',[_c('el-tag',[_vm._v(_vm._s(sizeList[0].sizeName || '暂无尺码'))])],1):_c('div',[_c('el-popover',{attrs:{"placement":"bottom","width":"200","trigger":"hover","visible-arrow":false,"popper-class":"assignData-style"}},[_c('div',[_c('p',{staticClass:"assignData-style-header"},[_c('span',{staticClass:"assignData-style-header-font"},[_vm._v(" 全部尺码")])]),_c('div',{staticClass:"assignData-style-tag-concent"},_vm._l((sizeList),function(item){return _c('el-tag',{key:item.id,staticStyle:{"margin-right":"5px","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(item.sizeName || '暂无尺码')+" ")])}),1)]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-tag',[_vm._v(_vm._s(sizeList[0].sizeName || '暂无尺码'))]),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"margin-left":"5px","display":"inline-block"}})],1)])],1)]}},{key:"operationSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var row = ref_scoped.row;
var index = ref_scoped.index;
return [_c('ColorTextBtn',{on:{"click":function($event){return _vm.cancelOperation(row, index)}}},[_vm._v(_vm._s(row.operation ? '取消选择' : '选择'))])]}}])}),_c('PaginationBar',{attrs:{"size":_vm.size,"page":_vm.page,"total":_vm.finallyData.length},on:{"refreshTableEventFun":_vm.handlePageChange}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitCancelForm}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }