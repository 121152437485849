<template>
  <div class="batchCancelBtn">
    <el-button @click="showDialog" type="info" size="small" :loading="btnLonding">取消分配</el-button>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <span slot="title"> 取消分配 </span>
      <!-- <el-form :model="cancelForm" label-width="110px" :rules="cancelRules" ref="cancelFormRef">
        <el-form-item label="请选择负责人" prop="ownerId">
          <el-select v-model="cancelForm.ownerId" placeholder="请选择" style="width: 300px" clearable>
            <el-option value="1" label="111"></el-option>
            <el-option value="2" label="222"></el-option>
          </el-select>
          <p class="tipFont">取消负责人操作所选择数据上传刀版文件、编辑刀版文件的权限</p>
        </el-form-item>
      </el-form> -->
      <CommonTable height="auto" max-height="400px" :selection="false" :cols="tableCol1" :infoData="tableData">
        <template #mergeSlot="{ scoped }">
          <div class="merge-slot-class">
            <el-image style="width: 60px; height: 60px" :src="figure(scoped)" fit="contain"></el-image>
            <div>
              <div style="max-width: 200px">
                {{ scoped.name || '暂无名字' }}
              </div>
            </div>
          </div>
        </template>
        <template #CategorySlot="{ scoped }">
          {{ (scoped.productCategory && scoped.productCategory.name) || '暂无' }}
        </template>
        <template #structSlot="{ scoped: { styleList } }">
          <div v-if="!styleList.length">
            {{ '暂无款式' }}
          </div>
          <div v-else-if="styleList.length === 1">
            <el-tag>{{ styleList[0].name || '暂无款式名' }}</el-tag>
          </div>
          <div v-else>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover"
              :visible-arrow="false"
              popper-class="assignData-style"
            >
              <div>
                <p class="assignData-style-header">
                  <span class="assignData-style-header-font"> 全部款式</span>
                </p>
                <div class="assignData-style-tag-concent">
                  <el-tag v-for="item in styleList" :key="item.id" style="margin-right: 5px; margin-bottom: 5px">
                    {{ item.name || '暂无款式名' }}
                  </el-tag>
                </div>
              </div>
              <div slot="reference">
                <el-tag>{{ styleList[0].name || '暂无款式名' }}</el-tag>
                <i class="el-icon-arrow-down" style="margin-left: 5px; display: inline-block"></i>
              </div>
            </el-popover>
          </div>
        </template>
        <template #sizesSlot="{ scoped: { sizeList } }">
          <div v-if="!sizeList.length">
            {{ '暂无款式' }}
          </div>
          <div v-else-if="sizeList.length === 1">
            <el-tag>{{ sizeList[0].sizeName || '暂无尺码' }}</el-tag>
          </div>
          <div v-else>
            <el-popover
              placement="bottom"
              width="200"
              trigger="hover"
              :visible-arrow="false"
              popper-class="assignData-style"
            >
              <div>
                <p class="assignData-style-header">
                  <span class="assignData-style-header-font"> 全部尺码</span>
                </p>
                <div class="assignData-style-tag-concent">
                  <el-tag v-for="item in sizeList" :key="item.id" style="margin-right: 5px; margin-bottom: 5px">
                    {{ item.sizeName || '暂无尺码' }}
                  </el-tag>
                </div>
              </div>
              <div slot="reference">
                <el-tag>{{ sizeList[0].sizeName || '暂无尺码' }}</el-tag>
                <i class="el-icon-arrow-down" style="margin-left: 5px; display: inline-block"></i>
              </div>
            </el-popover>
          </div>
        </template>
        <template #operationSlot="{ scoped: { row, index } }">
          <ColorTextBtn @click="cancelOperation(row, index)">{{ row.operation ? '取消选择' : '选择' }}</ColorTextBtn>
        </template>
      </CommonTable>
      <PaginationBar
        :size="size"
        :page="page"
        :total="finallyData.length"
        @refreshTableEventFun="handlePageChange"
      ></PaginationBar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCancelForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { tableCol1 } from './tableCols.js'
import { getValueFromObj, parseImgSrc } from '@/utils'
import { deepClone } from '@/components/avue/utils/util'
import { assignData } from '../../api.js'
export default {
  components: {},
  props: {
    multipleSelection: {
      type: Array,
      required: true
    },
    clearArr: {
      type: Array,
      required: true
    },
    allData: {
      type: Array,
      required: true
    },
    checkedAll: {
      type: Boolean,
      required: true
    },
    btnLonding: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableCol1,
      finallyData: [],
      dialogVisible: false,
      cancelForm: { ownerId: '' },
      cancelRules: {
        ownerId: [{ required: true, message: '请选择负责人', trigger: 'change' }]
      },
      size: 10,
      page: 1
    }
  },
  computed: {
    // 拿到表格数据并进行处理
    tableData() {
      return this.finallyData.slice((this.page - 1) * this.size, this.page * this.size)
    },
    // 图片的路径如果没有就默认路径
    figure() {
      return (row) => {
        let src = getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        return src ? parseImgSrc(src) : require('@/assets/images/default.png')
      }
    }
  },
  watch: {
    dialogVisible: {
      handler(newVal) {
        this.page = 1
      }
    }
  },
  methods: {
    showDialog() {
      const allData = deepClone(this.allData)
      if (this.checkedAll) {
        const data = allData.filter(({ id }) => {
          return !this.clearArr.includes(id)
        })
        this.finallyData = data
      } else {
        const data = allData.filter(({ id }) => {
          return this.multipleSelection.includes(id)
        })
        this.finallyData = data
      }
      if (this.finallyData.length === 0) {
        this.$message.warning('请先选择数据')
        return
      }

      this.finallyData.map((item) => {
        this.$set(item, 'operation', true)
      })

      this.dialogVisible = true
    },
    submitCancelForm() {
      /*  this.$refs['cancelFormRef'].validate((valid) => {
        if (valid) {
          const choiceData = this.finallyData.filter((item) => {
            return item.operation === true
          })
          // 最终选中的数据
          // console.log('choiceData', choiceData)
          const idList = choiceData.map(({ id }) => {
            return id
          })
          this.cancelClick(idList)
          this.dialogVisible = false
        } else {
          return false
        }
      }) */
      const choiceData = this.finallyData.filter((item) => {
        return item.operation === true
      })
      // 最终选中的数据
      // console.log('choiceData', choiceData)
      const idList = choiceData.map(({ id }) => {
        return id
      })
      if (!idList.length) {
        this.$message.warning('请选择数据')
        return
      }
      this.cancelClick(idList)
      this.dialogVisible = false
    },
    cancelOperation(row) {
      row.operation = !row.operation
    },
    handlePageChange({ page, size }) {
      this.size = size
      this.page = page
    },
    // 批量取消
    async cancelClick(idList) {
      const newQuery = {
        productPrototypeIdList: idList
      }
      const { code } = await assignData(newQuery)
      if (code === 0) {
        this.$message.success('操作成功')
        this.sup_this.init()
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tipFont {
  margin: 0;
  padding: 0;
  color: #595961;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
}
::v-deep .assignData-style-header {
  margin: 0;
  border-bottom: 1px solid #dcdee0;
  .assignData-style-header-font {
    display: inline-block;
    color: $color-primary;
    border-bottom: 1px solid $color-primary;
    padding: 10px;
  }
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #dcdee0;
  color: #333;
}
::v-deep .assignData-style-tag-concent {
  padding: 10px;
}
</style>
